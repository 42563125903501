import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import NotFound from "./NotFound";

const ProtectedRoute = ({ element, allowedRoles }) => {
  let userRole = {};
  if (localStorage.getItem("admin_details")) {
    userRole = JSON.parse(localStorage.getItem("admin_details"));
  } // Fetch user role
  const location = useLocation();

  // Check if user has access
  if (userRole.employee_type) {
    if (!allowedRoles.includes(userRole.employee_type)) {
      return <NotFound />; // Render 404 if unauthorized
    }
  } else {
    if (!allowedRoles.includes(userRole.role)) {
      return <NotFound />; // Render 404 if unauthorized
    }
  }

  return element;
};

// Define PropTypes to prevent ESLint errors
ProtectedRoute.propTypes = {
  element: PropTypes.element.isRequired, // Expecting a React component
  allowedRoles: PropTypes.arrayOf(PropTypes.string).isRequired, // Array of strings
};

export default ProtectedRoute;
