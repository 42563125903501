import React, { Component } from "react";
import Constants from "Helpers/Constants";
import axios from "axios";
import DataTable from "examples/Tables/DataTable";
import card from "assets/theme/components/card";
import cardContent from "assets/theme/components/card/cardContent";
import init from "Helpers/WindowToken";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { Link, Navigate, redirect, useLocation } from "react-router-dom";
import {
  Autocomplete,
  Card,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  TextareaAutosize,
} from "@mui/material";
// import Textarea from "@mui/joy/Textarea";
import MDBox from "components/MDBox";
import MDSnackbar from "components/MDSnackbar";
import MDTypography from "components/MDTypography";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDButton from "components/MDButton";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";

const fd = new FormData();
export default class UpdateCareer extends Component {
  state = {
    allJobs: [],
    id: "",
    message: "",
    notification: false,
    per_pge: 20,
    page_no: 1,
    redirect: false,
    subject_poster: {},
    subject_name: "",
    testimonial: "",
  };

  componentDidMount() {
    let id = window.location.pathname.split("/")[2];
    this.setState({ id: id });
    if (id) {
      this.getSingleSubject(id);
    }
  }
  getSingleSubject = async (id) => {
    try {
      if (init() === "success") {
        const resp = await axios.get(Constants.getUrls.getAllSubjects + "/" + id);
        // console.log(resp);
        this.setState({
          subject_name: resp.data.data.subject_name,
          subject_poster: resp.data.data.subject_poster,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  handleInput = (e) => {
    // console.log(e.target.value);
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    fd.append("subject_name", this.state.subject_name);
    fd.append("subject_poster", this.state.subject_poster);
    // fd.append("content", this.state.testimonial);
    // return;
    if (init() === "success") {
      axios
        .post(Constants.putUrls.updateSubject + this.state.id, fd)
        .then((resp) => {
          // console.log(resp);
          this.setState({
            notification: true,
            message: resp.data.message,
          });
          setTimeout(() => {
            this.setState({ notification: false, message: "", redirect: true });
          }, 3000);
        })
        .catch((err) => {
          this.setState({
            notification: true,
            message: err.response.data.message,
          });
          setTimeout(() => {
            this.setState({ notification: false, message: "" });
          }, 5000);
        });
    }
  };

  render() {
    if (this.state.redirect === true) {
      return <Navigate to="/subjects" />;
    } else {
      return (
        <div>
          <div>
            <DashboardLayout>
              <DashboardNavbar />
              <MDBox pt={6} pb={3}>
                <Card>
                  <MDBox
                    mx={2}
                    mt={-3}
                    py={3}
                    px={2}
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="info"
                  >
                    <MDTypography variant="h6" color="white">
                      Update Subject
                    </MDTypography>
                  </MDBox>
                  {/* <Grid item sm={4} px={2} align="right">
                    <Link to="/admin/addNewJob">
                      <MDButton variant="contained" size="small" color="primary">
                        Create Job
                      </MDButton>
                    </Link>
                  </Grid> */}
                  <MDBox py={3} bgColor="white" px={2}>
                    <form onSubmit={this.handleSubmit}>
                      <Grid container sm={12} spacing={2}>
                        <Grid item sm={6}>
                          <TextField
                            type="text"
                            label="Subject Name"
                            name="subject_name"
                            value={this.state.subject_name}
                            onChange={this.handleInput}
                            style={{ width: "100%", height: "100%" }}
                          />
                        </Grid>

                        <Grid item sm={6}>
                          <TextField
                            type="file"
                            label="Subject Poster"
                            name="subject_poster"
                            focused
                            onChange={(e) => {
                              this.setState({
                                subject_poster: e.target.files[0],
                              });
                            }}
                            style={{ width: "100%", height: "100%" }}
                          />
                        </Grid>

                        {/* <Grid item sm={6}>
                          <TextField
                            type="text"
                            label="Testimonial"
                            name="testimonial"
                            onChange={this.handleInput}
                            style={{ width: "100%", height: "100%" }}
                          />
                        </Grid> */}
                      </Grid>

                      <Grid align="left" mt={2}>
                        <MDButton variant="contained" size="small" color="info" type="submit">
                          {this.state.loading ? "Updating..." : "Update"}
                        </MDButton>
                      </Grid>
                    </form>
                  </MDBox>
                  <>
                    <MDSnackbar
                      color="success"
                      icon="notifications"
                      title="AgriQuiz"
                      content={this.state.message}
                      dateTime="Just now"
                      open={this.state.notification}
                      //   close={toggleSnackbar}
                    />
                  </>
                </Card>
              </MDBox>
            </DashboardLayout>
          </div>
        </div>
      );
    }
  }
}
