const NotFound = () => {
  return (
    <div style={{ textAlign: "center", marginTop: "50px" }}>
      <h1>404 - Not Found</h1>
      <p>The page you looking is no access for you.</p>
    </div>
  );
};

export default NotFound;
