import React, { Component } from "react";
import Constants from "Helpers/Constants";
import axios from "axios";
import DataTable from "examples/Tables/DataTable";
import card from "assets/theme/components/card";
import cardContent from "assets/theme/components/card/cardContent";
import init from "Helpers/WindowToken";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { Card, FormControl, Grid, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import MDBox from "components/MDBox";
import MDSnackbar from "components/MDSnackbar";
import MDTypography from "components/MDTypography";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDButton from "components/MDButton";
import { Link, Navigate } from "react-router-dom";

export default class TestSeries extends Component {
  state = {
    secondaryCategories: [],
    per_page: 20,
    page_no: 1,
    notiColor: "success",
    notification: false,
    message: "",
    loading: false,
  };
  componentDidMount() {
    this.getAllTestSeries();
  }

  getAllTestSeries = () => {
    this.setState({ loading: true });
    if (init() === "success") {
      axios
        .get(Constants.getUrls.getAllTestSeries)
        .then((resp) => {
          // console.log(resp.data.data);
          this.setState({
            secondaryCategories: resp.data.data.docs,
            loading: false,
          });
        })
        .catch((err) => {
          this.setState({
            notiColor: "warning",
            message: "Something went wrong!",
            notification: true,
          });
          setTimeout(() => {
            this.setState({
              notification: false,
              message: "",
              notiColor: "success",
              loading: false,
            });
          }, 3500);
        });
    } else {
      this.setState({
        loading: false,
      });
    }
  };

  applicationStatus = (job_id, status) => {
    console.log("function called");

    if (init() === "success") {
      axios
        .put(Constants.putUrls.applicationStatus, { job_id, response_status: status })
        .then((resp) => {
          this.setState(
            {
              notification: true,
              noticolor: "success",
              message: resp.data.message,
            },
            () => {
              this.getAllTestSeries();
            }
          );
          setTimeout(() => {
            this.setState({
              notification: false,
              message: "",
            });
          }, 2500);
        });
    }
  };

  render() {
    return (
      <div>
        <DashboardLayout>
          <DashboardNavbar />
          <MDBox pt={6} pb={3}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Test Series
                </MDTypography>
              </MDBox>
              <Grid item sm={4} px={2} align="right">
                <Link to="/addTestseries">
                  <MDButton variant="contained" size="small" color="primary">
                    Create New
                  </MDButton>
                </Link>
              </Grid>
              <MDBox py={3} bgColor="white">
                {this.state.loading ? (
                  <MDBox>
                    <Grid align="center">
                      <h4>Loading...</h4>
                    </Grid>
                  </MDBox>
                ) : (
                  <>
                    {this.state.secondaryCategories.length > 0 ? (
                      <DataTable
                        table={{
                          columns: [
                            { Header: "serial", accessor: "serial", width: "6%" },
                            { Header: "name", accessor: "test_series" },
                            // { Header: "email", accessor: "email" },
                            { Header: "poster", accessor: "small" },
                            {
                              Header: "seats",
                              accessor: "seats",
                            },
                            {
                              Header: "price",
                              accessor: "price",
                            },
                            {
                              Header: "offer price",
                              accessor: "offer_price",
                            },

                            // { Header: "jop_profile", accessor: "job_profile" },

                            // { Header: "file", accessor: "file" },
                            // { Header: "action", accessor: "action" },
                          ],
                          rows: this.state.secondaryCategories.map((sec, index) => ({
                            serial: index + 1,
                            test_series: sec?.test_series_name,
                            // email: sec.email,
                            small: (
                              <>
                                <img
                                  src={Constants.imageDomain + sec.small_poster}
                                  style={{ height: "100px", width: "250px" }}
                                />
                              </>
                            ),
                            seats: sec?.seats,
                            price: "₹ " + sec?.price,
                            offer_price: "₹ " + sec?.offer_price,
                            // job_profile: sec?.career_id?.career,
                            // file: <a href={"https://core.plantbae.in" + sec?.resume}> See File </a>,
                            // status: (
                            //   <>
                            //     <FormControl fullWidth>
                            //       <InputLabel>Application Status</InputLabel>
                            //       <Select
                            //         value={sec?.response_status}
                            //         onChange={(e) => this.applicationStatus(sec.id, e.target.value)}
                            //         label="Application Status"
                            //         name="response_status"
                            //         style={{
                            //           height: "45px",
                            //           color: "red",
                            //         }}
                            //       >
                            //         <MenuItem value="pending">Pending</MenuItem>
                            //         <MenuItem value="hired">Hired</MenuItem>
                            //         <MenuItem value="rejected">Rejected</MenuItem>
                            //       </Select>
                            //     </FormControl>
                            //   </>
                            // ),
                            // action: (
                            //   <>
                            //     <Link
                            //       to={{
                            //         pathname: "/applicant-details/" + sec.user_id,
                            //         state: { data: sec },
                            //       }}
                            //     >
                            //       <MDButton variant="outlined" size="small" color="dark">
                            //         User Details
                            //       </MDButton>
                            //     </Link>
                            //   </>
                            // ),
                          })),
                        }}
                      />
                    ) : (
                      <div>No Data Found!</div>
                    )}
                  </>
                )}
              </MDBox>
              <>
                <MDSnackbar
                  color={this.state.notiColor}
                  icon="notifications"
                  title="Agriquiz"
                  content={this.state.message}
                  dateTime="Just now"
                  open={this.state.notification}
                  //   close={toggleSnackbar}
                />
              </>
            </Card>
          </MDBox>
        </DashboardLayout>
      </div>
    );
  }
}
